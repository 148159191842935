import {
  useRef,
  useState,
  useEffect,
  ReactElement,
  Suspense,
  lazy,
} from 'react';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addSDSToLocation, getAllPdfs, getSubstancesV2 } from 'api';
import { addSubstanceToLocations } from 'api/inventory/post';
import { renderSnackbar } from 'utils';
import { isOwner, isAdmin, checkUserPermissions } from 'utils/userRoleUtils';
import {
  getLocationDetailPath,
  extractLocation,
  deferImport,
} from 'utils/helper';
import { userDataSelector } from 'services/user/selectors';
import {
  commonLocationDataSelector,
  favoriteLocationDataSelector,
  locationDataSelector,
} from 'services/location/selectors';
import { ExtractedLocationItem } from 'interfaces/location-management';
import {
  GetAllPdfsRequest,
  GetAllPdfsResponse,
  GetAllPdfsResponseDto,
} from 'interfaces/substance/GlobalSearch';
import { ApiResponse } from 'interfaces/common/ApiResponseInterface';
import { HomeSearchSubstanceResult } from 'interfaces/home';
import {
  GetSubstanceRequestDto,
  SubstanceInterface,
  SubstanceLocationPath,
  SubstanceResponseDto,
} from 'interfaces/substance';
import { PERMISSION } from 'enums/permissions.enums';
import {
  SUBSTANCE_FILTER_APPROVAL_STATUS,
  SUBSTANCE_FILTER_LOCATION_STATUS,
  SUBSTANCE_FILTER_RISK_ASSESSMENT_STATUS,
  SUBSTANCE_FILTER_SDS_STATUS,
} from 'enums/substance-filter-enum';
/* Components */
import {
  Button,
  ClickAwayListener,
  MenuItem,
  Popper,
  CircularProgress,
  ClassNameMap,
} from '@mui/material';
import {
  Search,
  PictureAsPdf,
  ArrowBackIosNewOutlined as ArrowBackIosNewOutlinedIcon,
  LaunchOutlined as LaunchOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
} from '@mui/icons-material';
import SubmitButton from 'components/commons/submit-button';
import LoadingModal from 'components/loader/LoadingModal';

/* Lazy load components */
const SDSDetailPopup = lazy((): Promise<any> => {
  return deferImport(import('components/popup/sds-detail-popup'));
});
const SelectMultipleLocationsPopup = lazy((): Promise<any> => {
  return deferImport(import('components/popup/select-multiple-location-popup'));
});
const AddSubstanceResultPopup = lazy((): Promise<any> => {
  return deferImport(import('components/popup/add-substance-result-popup'));
});
const ConfirmAddSubstancePopup = lazy((): Promise<any> => {
  return deferImport(
    import(
      'components/popup/confirm-add-substance-popup/ConfirmAddSubstancePopup'
    )
  );
});
/* End */

const HomeSearch = () => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'home', 'sds_table']);
  const classes: ClassNameMap = useStyles();
  const searchRef = useRef<HTMLDivElement>(null);
  /* Selector */
  const allLocations = useSelector(locationDataSelector);
  const user = useSelector(userDataSelector);
  const locations = useSelector(commonLocationDataSelector);
  const favoriteLocations = useSelector(favoriteLocationDataSelector);
  /* State */
  const [extractedLocation, setExtractedLocation] = useState<
    Array<ExtractedLocationItem>
  >([]);
  const [searchLocation, setSearchLocation] = useState<
    Array<ExtractedLocationItem>
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const [searchSubstance, setSearchSubstance] = useState<
    HomeSearchSubstanceResult[]
  >([]);
  const [searchSds, setSearchSds] = useState<GetAllPdfsResponseDto[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [keyWord, setKeyword] = useState<string>('');
  const [deadlock, setDeadlock] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [value, setValue] = useState<string>('');
  const [loadSubstance, setLoadSubstance] = useState<boolean>(false);
  const [loadLocation, setLoadLocation] = useState<boolean>(false);
  const [loadSds, setLoadSds] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<boolean>(false);
  const [sdsDetailPopup, setSdsDetailPopup] = useState<ReactElement | null>(
    null
  );
  const [openAddPopup, setOpenAddPopup] = useState<boolean>(false);
  const [selectedSDS, setSelectedSDS] = useState<string | number | null>(null);
  const [openConfirmPopup, setOpenConfirmPopup] = useState<boolean>(false);
  const [addSdsResultPopup, setAddSdsResultPopup] =
    useState<ReactElement | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<
    string | number | null
  >(null);
  const [haveNotFoundLocation, setHaveNotFoundLocation] =
    useState<boolean>(false);

  const loadSearch =
    (globalSearch && loadSds) ||
    (!globalSearch && (loadLocation || loadSubstance));

  const fetchSubstance = (): Promise<void> => {
    let bodyRequest: GetSubstanceRequestDto = {
      page: 1,
      page_size: 5,
      duplicated: false,
      is_archived: false,
      without_linked_sds: false,
      producer_name: [],
      revision_date: [],
      search: keyWord,
      sds_status: SUBSTANCE_FILTER_SDS_STATUS.USE,
      approval_status: SUBSTANCE_FILTER_APPROVAL_STATUS.ALL,
      locations: SUBSTANCE_FILTER_LOCATION_STATUS.ALL,
      risk_assessment: SUBSTANCE_FILTER_RISK_ASSESSMENT_STATUS.ALL,
    };
    const getSubstancesRequest = getSubstancesV2(bodyRequest);
    return getSubstancesRequest.then(
      (response: ApiResponse<SubstanceResponseDto>) => {
        if (response.status === 200) {
          const newArray: HomeSearchSubstanceResult[] = [];
          if (response?.data?.results?.length > 0) {
            response.data.results.forEach((item: SubstanceInterface) => {
              const location = item.used_in_locations_data
                ? item.used_in_locations_data.filter(
                    (el: SubstanceLocationPath) => el.id === item.location
                  )
                : [];
              const locationName = location.length > 0 ? location[0].name : '';
              newArray.push({
                id: item.id,
                pdfId: item.linked_sds_pdf,
                locationId: item.location,
                name: item.sds_info?.sds_pdf_product_name,
                locationName: locationName,
              });
            });
          }
          if (newArray.length > 5) setSearchSubstance(newArray.slice(0, 5));
          else setSearchSubstance(newArray);
          setLoadSubstance(false);
          return;
        }
        setSearchSubstance([]);
        setLoadSubstance(false);
      }
    );
  };

  const fetchGlobalSds = (): void => {
    const lang = user?.language_code ?? 'en';
    const region = user?.region?.short_name ?? 'All';

    /*
    Retrieve the advanced search state from localStorage.
    */
    const preSelectedLanguage = localStorage.getItem(
      'global_advanced_search_language'
    );
    const preSelectedRegion = localStorage.getItem(
      'global_advanced_search_region'
    );
    const selectedUseByCustomer =
      localStorage.getItem('global_advanced_use_by_customer') == 'true';
    const preSelectedYear = localStorage.getItem(
      'global_advanced_search_revision_year'
    );

    /* End */

    const selectedLanguage = preSelectedLanguage || lang;
    const selectedRegion = preSelectedRegion || region;
    const selectedYear = preSelectedYear || 'all';

    let bodyRequest: GetAllPdfsRequest = {
      search_product: keyWord,
      search_producer: '',
      page: 1,
      page_size: 10,
      language_code: selectedLanguage !== 'all' ? selectedLanguage : null,
      region: selectedRegion === 'all' ? null : selectedRegion,
      is_in_use_by_customer: selectedUseByCustomer,
      with_used_in_locations: true,
      minimum_year: selectedYear === 'all' ? '' : selectedYear,
    };
    const getAllPdfsRequest = getAllPdfs(bodyRequest);
    getAllPdfsRequest.then((response: GetAllPdfsResponse) => {
      if (response.status === 200) {
        setSearchSds(response.data?.results);
        setLoadSds(false);
      }
    });
  };

  const fetchLocation = (): void => {
    const res = extractedLocation.filter((item: ExtractedLocationItem) => {
      return item.path.toLowerCase().includes(keyWord.toLowerCase());
    });
    if (res.length === 0) setHaveNotFoundLocation(true);
    setSearchLocation(res.slice(0, 5));
    setLoadLocation(false);
  };

  const onOpen = (): void => {
    if (!keyWord) return;
    setOpen(true);
  };

  const handleAddSubstanceToLocations = (
    selectedLocations: number[]
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      addSubstanceToLocations({
        sds_id: [selectedSDS],
        locations: selectedLocations,
      }).then((response: ApiResponse<any>) => {
        if (response.status === 200) {
          resolve(true);
          setOpenAddPopup(false);
          setAddSdsResultPopup(
            <AddSubstanceResultPopup
              onClose={() => {
                setAddSdsResultPopup(null);
              }}
              successfulMessages={response.data.successful}
              errorMessages={response.data.errors}
            />
          );
        } else {
          reject(false);
        }
      });
    });
  };

  const addSdsToLibrarySubmit = (
    sdsItem: GetAllPdfsResponseDto
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const locationId =
        locations[0] && locations[0].id
          ? locations[0].id
          : favoriteLocations[0].id;

      const addSDSToLocationRequest = addSDSToLocation(
        locationId,
        [sdsItem.id],
        false,
        true
      );
      addSDSToLocationRequest.then((response: ApiResponse<any>) => {
        if (response.data?.archived_existed) {
          renderSnackbar([t('sds_table:add_substance_warning')]);
          setOpenConfirmPopup(true);
          resolve(true);
          return;
        }
        if (response?.status === 201) {
          setSelectedLocation(locationId);
          renderSnackbar([
            t('sds_table:sds_for_added_successfully', {
              sds_name: sdsItem.sds_pdf_product_name,
            }),
          ]);
          resolve(true);
        } else reject(false);
      });
    });
  };

  const handleForkAddSds = (sdsId: string): Promise<boolean> => {
    return new Promise(resolve => {
      const checkAddSDSToLocationRequest = addSDSToLocation(
        locations[0].id,
        [sdsId],
        true
      );
      checkAddSDSToLocationRequest.then((response: ApiResponse<any>) => {
        if (response?.status === 201) setSelectedLocation(selectedLocation);

        setSelectedSDS(null);
        resolve(true);
        setOpenConfirmPopup(false);
      });
    });
  };

  const resetLoading = (): void => {
    setLoadLocation(false);
    setLoadSubstance(false);
    setLoadSds(false);
    setHaveNotFoundLocation(false);
  };

  const onClickLocation = (id: string): void => {
    if (!id) {
      renderSnackbar([t('common:location_does_not_exist')]);
      return;
    }
    setOpen(false);
    if (isOwner(user) || isAdmin(user))
      history.push(`/location-management/location-detail/${id}`);
    else history.push(`/my-sds/location-detail/${id}`);
  };

  const onClickSubstance = (item: HomeSearchSubstanceResult): void => {
    if (!item || !item.pdfId || !item.locationId) {
      renderSnackbar([t('common:substance_does_not_exist')]);
      return;
    }
    setOpen(false);
    if (isOwner(user) || isAdmin(user)) {
      history.push(
        getLocationDetailPath(
          '/location-management',
          item.locationId,
          item.id,
          true
        )
      );
    } else history.push(getLocationDetailPath('/my-sds', undefined, item.id));
  };

  const onClickSds = (item: GetAllPdfsResponseDto): void => {
    if (!item) {
      renderSnackbar([t('common:sds_does_not_exist')]);
      return;
    }
    setSdsDetailPopup(
      <SDSDetailPopup
        open={true}
        sdsId={item.id}
        onClose={() => {
          setSdsDetailPopup(null);
        }}
      />
    );
  };

  const handleOpenSDS = (sdsFile: GetAllPdfsResponseDto): void => {
    if (!sdsFile.link_to_public_view) {
      renderSnackbar([
        t('custom_table:this_substance_does_not_have_a_sds_file'),
      ]);
      return;
    }
    const aElement = document.createElement('a');
    aElement.target = '_blank';
    aElement.href = `${sdsFile.link_to_public_view}`;
    aElement.click();
  };

  const showAddToSDSLibraryButton = (): boolean => {
    if (locations.length > 1) return false;
    if (favoriteLocations.length > 1) return false;
    if (locations.length === 1) {
      if (locations[0].children.length > 0) return false;
      if (favoriteLocations.length === 1) {
        if (locations[0].id === favoriteLocations[0].id) return true;
        return false;
      }
      return true;
    }
    if (favoriteLocations.length === 1) return true;
    return false;
  };

  const renderButtonAdd = (row: GetAllPdfsResponseDto): ReactElement | null => {
    if (
      !favoriteLocations ||
      !locations ||
      !checkUserPermissions(PERMISSION.ADD_SDS_FROM_GLOBAL_DATABASE)
    )
      return null;

    if (showAddToSDSLibraryButton()) {
      return (
        <SubmitButton
          onSave={() => {
            setSelectedSDS(row.id);
            return addSdsToLibrarySubmit(row);
          }}
          color={'primary'}
          variant={'contained'}
          style={{ fontSize: 13, whiteSpace: 'nowrap' }}
        >
          {t('sds_table:add_to_sds_library')}
        </SubmitButton>
      );
    }

    return (
      <Button
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          setOpenAddPopup(true);
          setSelectedSDS(row.id);
        }}
        color={'primary'}
        variant={'contained'}
        style={{
          whiteSpace: 'nowrap',
          padding: '5px 10px',
          fontSize: 13,
        }}
      >
        {t('sds_table:add_to_location')}
      </Button>
    );
  };

  const renderLocations = (): ReactElement => {
    if (loadLocation) {
      return (
        <MenuItem style={{ color: '#5B5B5B' }}>{t('common:loading')}</MenuItem>
      );
    }

    return (
      <>
        {searchLocation.map((item: ExtractedLocationItem, index: number) => (
          <MenuItem
            key={index}
            onClick={() => {
              setValue(item.path);
              onClickLocation(item.id as string);
            }}
            style={{ color: '#5B5B5B', whiteSpace: 'normal' }}
          >
            {item.path}
          </MenuItem>
        ))}
      </>
    );
  };

  const renderSubstance = (): ReactElement => {
    if (loadSubstance) {
      return (
        <MenuItem style={{ color: '#5B5B5B' }}>{t('common:loading')}</MenuItem>
      );
    }

    return (
      <>
        {searchSubstance.map(
          (item: HomeSearchSubstanceResult, index: number) => (
            <MenuItem
              key={index}
              onClick={() => {
                setValue(item.name ?? '');
                onClickSubstance(item);
              }}
              style={{ color: '#5B5B5B', whiteSpace: 'normal' }}
            >
              {`${item.name} (${item.locationName})`}
            </MenuItem>
          )
        )}
      </>
    );
  };

  const renderSds = (): ReactElement => {
    if (loadSds) {
      return (
        <MenuItem style={{ color: '#5B5B5B' }}>{t('common:loading')}</MenuItem>
      );
    }

    if (searchSds && searchSds.length > 0) {
      return (
        <>
          {searchSds.map((item: GetAllPdfsResponseDto, index: number) => (
            <MenuItem
              key={index}
              onClick={() => {
                setValue(item.sds_pdf_product_name ?? '');
              }}
            >
              <div className={classes.sdsResultWrapper}>
                <div
                  className={classes.sdsResultInfo}
                  onClick={() => onClickSds(item)}
                >
                  <div className={classes.sdsResultName}>
                    <div> {item.sds_pdf_product_name} </div>
                    <div className={classes.sdsResultSupplier}>
                      {item.sds_pdf_manufacture_name}
                    </div>
                  </div>
                  <div> {item.sds_pdf_revision_date} </div>
                </div>
                <div className={classes.searchResultAction}>
                  <div>
                    <PictureAsPdf
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpenSDS(item);
                      }}
                    />
                  </div>
                  <div>{renderButtonAdd(item)}</div>
                </div>
              </div>
            </MenuItem>
          ))}
        </>
      );
    }

    return (
      <MenuItem style={{ color: '#5B5B5B' }}>
        {t('common:no_result_found')}
      </MenuItem>
    );
  };

  const renderGeneralSuggestion = (): ReactElement => {
    const refWidth = searchRef?.current?.offsetWidth ?? 360;
    return (
      <div
        className={classes.searchSuggestion}
        style={{
          width: `${refWidth - 40}px`,
        }}
      >
        {loadSubstance && loadLocation ? (
          <MenuItem style={{ color: '#5B5B5B' }}>
            {t('common:loading')}
          </MenuItem>
        ) : (
          <>
            {searchLocation && searchLocation.length > 0 ? (
              <div className={classes.searchList}>
                <div className={classes.searchCategory}>
                  {' '}
                  {t('common:locations')}{' '}
                </div>
                {renderLocations()}
              </div>
            ) : null}
            {searchSubstance && searchSubstance.length > 0 ? (
              <div className={classes.searchList}>
                <div className={classes.searchCategory}>
                  {' '}
                  {t('home:sds_in_use')}{' '}
                </div>
                {renderSubstance()}
              </div>
            ) : null}
            <p
              className={classes.redoText}
              onClick={() => {
                setGlobalSearch(true);
                setLoadSds(true);
              }}
            >
              {t('home:redo_search_using_global_database')}
              <ChevronRightOutlinedIcon sx={{ fontSize: '16px' }} />
            </p>
          </>
        )}
      </div>
    );
  };

  const renderGlobalSdsSuggestion = (): ReactElement => {
    const refWidth = searchRef?.current?.offsetWidth ?? 360;
    return (
      <div
        className={classes.searchSuggestion}
        style={{
          width: `${refWidth - 40}px`,
        }}
      >
        <div className={classes.searchList}>
          <div className={classes.searchCategory}>
            <ArrowBackIosNewOutlinedIcon
              className={classes.backIcon}
              sx={{ fontSize: '14px' }}
              onClick={() => {
                setLoadSubstance(true);
                setLoadLocation(true);
                setGlobalSearch(false);
                resetLoading();
              }}
            />
            {t('home:global_sds')}{' '}
          </div>
          {renderSds()}
        </div>
        <p
          className={classes.redoText}
          onClick={() => {
            history.push(`/global-sds-search/?s=${keyWord}`);
          }}
        >
          {t('home:see_more')}
          <LaunchOutlinedIcon sx={{ fontSize: '16px' }} />
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (allLocations?.length > 0) {
      const res = extractLocation(allLocations);
      setExtractedLocation(res);
      setSearchLocation(res.slice(0, 5));
      setLoadLocation(false);
    }
  }, [allLocations]);

  useEffect(() => {
    if (!keyWord) {
      setOpen(false);
      resetLoading();
      return;
    }
    if (globalSearch) {
      setGlobalSearch(false);
      resetLoading();
    }
    if (extractedLocation.length > 0) {
      fetchSubstance().then(() => fetchLocation());
      return;
    }
    resetLoading();
  }, [keyWord]);

  useEffect(() => {
    if (!keyWord) {
      setOpen(false);
      resetLoading();
      return;
    }
    if (globalSearch) {
      fetchGlobalSds();
      return;
    }
  }, [globalSearch]);

  useEffect(() => {
    if (haveNotFoundLocation) {
      if (searchSubstance.length === 0) {
        setGlobalSearch(true);
        setLoadSds(true);
        return;
      }
    }
  }, [haveNotFoundLocation]);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div className={classes.searchContainer}>
          <div
            className={classes.searchWrapper}
            onClick={e => {
              setAnchorEl(e.currentTarget);
              onOpen();
              resetLoading();
            }}
            ref={searchRef}
          >
            <div className={classes.searchIconContent}>
              <Search height={16} width={16} style={{ color: '#52525F' }} />
            </div>
            <input
              type="text"
              id="input-dashboard-search"
              className={classes.searchInput}
              placeholder={t('sds_table:search_sds_in_use_and_global_db')}
              autoComplete="off"
              value={value}
              onChange={e => {
                setValue(e.target.value);
                if (deadlock) clearTimeout(deadlock);

                if (globalSearch) setLoadSds(true);
                else {
                  setLoadLocation(true);
                  setLoadSubstance(true);
                }

                setDeadlock(
                  setTimeout(() => {
                    setKeyword(e.target.value);
                    setOpen(true);
                  }, 1500)
                );
              }}
              style={{ width: '100%' }}
              maxLength={255}
            />
            {loadSearch && (
              <CircularProgress style={{ width: '20px', height: '20px' }} />
            )}
          </div>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{
              zIndex: 999,
            }}
          >
            {!globalSearch && renderGeneralSuggestion()}
            {globalSearch && renderGlobalSdsSuggestion()}
          </Popper>
        </div>
      </ClickAwayListener>

      <Suspense fallback={<LoadingModal />}>
        {sdsDetailPopup}

        {addSdsResultPopup}

        {selectedSDS && openAddPopup && (
          <SelectMultipleLocationsPopup
            title={t('sds_table:add_substance_to_locations')}
            onClose={() => {
              setOpenAddPopup(false);
              setSelectedSDS(null);
            }}
            renderActions={(selectedLocations: number[]) => (
              <SubmitButton
                variant={'contained'}
                color={'primary'}
                onSave={() => {
                  return handleAddSubstanceToLocations(selectedLocations);
                }}
                style={{ width: 80 }}
                disabled={selectedLocations.length === 0}
              >
                {t('common:add')}
              </SubmitButton>
            )}
          />
        )}

        {openConfirmPopup && (
          <ConfirmAddSubstancePopup
            onClose={() => setOpenConfirmPopup(false)}
            open={openConfirmPopup}
            onSubmit={handleForkAddSds}
            id={selectedSDS}
          />
        )}
      </Suspense>
    </>
  );
};

export default HomeSearch;
